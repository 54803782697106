<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner pt-6">
      <v-card class="px-8 py-12">
        <Tip :dialog="dialog" @closeForm="closeTip"></Tip>
        <v-row>
          <v-col cols="3">
            <v-img src="../../assets/images/logos/LogoOrange.png" max-width="40px"></v-img>
          </v-col>
          <v-col cols="9">
            <div
              class="d-flex justify-center"
              style="font-size: 25px; font-weight: bold; margin-left: -80px; margin-top: 3px"
            >
              <span>新規登録</span>
            </div>
          </v-col>
        </v-row>
        <template v-if="apierror.status === 'error'">
          <div v-for="msg of apierror.messages" :key="msg">
            <v-row class="ml-0 mt-3 mb-1">
              <span style="color: red">* {{ msg }} </span>
            </v-row>
          </div>
        </template>
        <div class="my-4">
          <span>新しいアカウントを作成します</span>
        </div>
        <v-text-field
          v-model="$v.emailAddress.$model"
          :error-messages="emailAddressErrors"
          hide-details="auto"
          placeholder="メールアドレス"
          outlined
          dense
          color="primary"
          @keydown.enter="VerifyEmail"
        ></v-text-field>

        <v-row class="my-4" no-gutters>
          <v-col cols="9">
            <span>アカウントをお持ちの方は</span>
          </v-col>
          <v-col cols="3" class="d-flex justify-end">
            <div class="pointer">
              <span style="text-decoration: underline; color: #ff6e40" @click="$router.push('/login')"> ログイン </span>
            </div>
          </v-col>
        </v-row>
        <v-btn depressed block color="primary" :loading="submitStatus" @click="VerifyEmail">
          <span style="color: #fff">送信</span>
        </v-btn>
      </v-card>
    </div>
  </div>
</template>

<script>
import agent from '@/api/agent'
import { email, required } from 'vuelidate/lib/validators'
// eslint-disable-next-line import/no-extraneous-dependencies

import Tip from './TipRegister.vue'

export default {
  components: {
    Tip,
  },
  data: () => ({
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    emailAddress: '',
    submitStatus: false,

    dialog: false,
  }),
  computed: {
    emailAddressErrors() {
      const errors = []
      if (!this.$v.emailAddress.$dirty) return errors
      // eslint-disable-next-line
      !this.$v.emailAddress.required && errors.push('メールアドレスを入力してください。')
      // eslint-disable-next-line
      !this.$v.emailAddress.email && errors.push('メールアドレスを正しく入力してください。')

      return errors
    },
  },
  validations: {
    emailAddress: {
      required,
      email,
    },
  },
  methods: {
    VerifyEmail() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true
        agent.Register.sendVerifyMail({
          email_address: this.emailAddress,
        })
          .then(() => {
            this.submitStatus = false
            this.dialog = true
          })
          .catch(error => {
            this.submitStatus = false
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          })
      }
    },
    closeTip() {
      this.dialog = false
      this.$router.push('/')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.pointer :hover {
  cursor: pointer;
}
</style>
